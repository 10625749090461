import axios, { AxiosError, AxiosRequestConfig } from "axios";
import {
	isValidationProblemResponse,
	KanavaApiException,
	KanavaApiValidationException,
} from "./error";
import { readFromCache, writeToCache } from "./cache";
import { setOnlineStatus } from "../context/NetworkStatusProvider";
import { camelCase } from "lodash";

export interface UnknownResult {
	errorMessage?: string;
	errors?: any;
}

let token: string | null = null;

export function setToken(newToken: string | null) {
	token = newToken;
}

/**
 * Maps an object containing errors with dotted keys like { "user.name": "E1", "user.email": "E2" }
 * to an object hierarchy containing simple keys { "user": { "name": "E1", "email": "E2" } }
 */
function mapDottedErrorsToObjectHierarchy(
	inputErrors: Record<string, readonly string[]>
) {
	const mappedErrors: any = {};

	for (const [fullKey, value] of Object.entries(inputErrors)) {
		const keys = fullKey.split(".");
		let parent = mappedErrors;
		for (let keyIndex = 0; keyIndex < keys.length; keyIndex++) {
			const key = camelCase(keys[keyIndex]);
			const last = keyIndex === keys.length - 1;
			if (last) {
				parent[key] = value;
			} else {
				let child = parent[key];
				if (!child) {
					child = {};
					parent[key] = child;
				}
				parent = child;
			}
		}
	}

	return mappedErrors;
}

function toKanavaApiException(err: any) {
	const error = err as AxiosError;
	const data = error.response?.data;
	if (isValidationProblemResponse(data)) {
		const mappedErrors = mapDottedErrorsToObjectHierarchy(data.errors);
		return new KanavaApiValidationException(
			error.message,
			error.response?.status,
			mappedErrors
		);
	} else {
		return new KanavaApiException(error.message, error.response?.status);
	}
}

interface ApiClientCancellableRequest<T> {
	promise: Promise<T>;
	abort: () => void;
}

export class ApiClient {
	public static get<T>(url: string): ApiClientCancellableRequest<T> {
		const controller = new AbortController();
		return {
			promise: ApiClient.getCore<T>(url, controller.signal),
			abort: () => controller.abort(),
		};
	}

	public static async post<T>(url: string, data: any) {
		try {
			const res = await axios.post(
				fillApiUrl(url),
				data,
				ApiClient.getConfig()
			);
			const responseData = res.data as UnknownResult | undefined;

			return responseData as T;
		} catch (err) {
			throw toKanavaApiException(err);
		}
	}

	public static async postMultipartFormData<T>(url: string, data: any) {
		try {
			const res = await axios.post(
				fillApiUrl(url),
				data,
				ApiClient.getConfigForMultipartForm()
			);
			const responseData = res.data as UnknownResult | undefined;

			return responseData as T;
		} catch (err) {
			throw toKanavaApiException(err);
		}
	}

	public static async put<T>(url: string, data: any) {
		try {
			const res = await axios.put(
				fillApiUrl(url),
				data,
				ApiClient.getConfig()
			);
			const responseData = res.data as UnknownResult | undefined;

			return responseData as T;
		} catch (err) {
			throw toKanavaApiException(err);
		}
	}

	public static async delete<T>(url: string) {
		try {
			const res = await axios.delete(
				fillApiUrl(url),
				ApiClient.getConfig()
			);
			const responseData = res.data as UnknownResult | undefined;

			return responseData as T;
		} catch (err) {
			throw toKanavaApiException(err);
		}
	}

	private static getConfig(): AxiosRequestConfig {
		return {
			withCredentials: true,
			headers: token
				? {
						"X-Kanava-Token": token,
				  }
				: {},
		};
	}

	private static getConfigForMultipartForm(): AxiosRequestConfig {
		const tokenHeader = token
			? {
					"X-Kanava-Token": token,
			  }
			: {};

		return {
			withCredentials: true,
			headers: {
				...tokenHeader,
				"Content-Type": "multipart/form-data",
			} as any,
		};
	}

	private static async getCore<T>(
		url: string,
		abortSignal: AbortSignal
	): Promise<T> {
		let returnValue: T = {} as T;
		try {
			const response = await axios.get(fillApiUrl(url), {
				...ApiClient.getConfig(),
				signal: abortSignal,
			});
			const data = response.data as T;
			writeToCache(url, data);
			setOnlineStatus(true);
			returnValue = data;
		} catch (error) {
			let shouldThrow = true;
			if (isCanceled(error)) {
				throw error;
			}
			if (error.code === AxiosError.ERR_NETWORK) {
				setOnlineStatus(false);
				const cachedResponse = readFromCache(url);
				if (cachedResponse) {
					returnValue = cachedResponse;
					shouldThrow = false;
				}
			}
			if (shouldThrow) {
				throw toKanavaApiException(error);
			}
		}
		return returnValue;
	}
}

function fillApiUrl(url: string) {
	return `${getApiBaseUrl()}${url}`;
}

function getApiBaseUrl() {
	const envPort = process.env.REACT_APP_API_PORT;
	const envUrl = process.env.REACT_APP_API_URL;
	const envProtocol = process.env.REACT_APP_API_PROTOCOL;

	if (envUrl) return envUrl;

	if (!envPort && process.env.NODE_ENV === "production") {
		return "/api/";
	}

	const port = envPort ? parseInt(envPort, 10) : 80;

	const protocol = envProtocol || "http";

	const hostName = window.location.hostname;
	return `${protocol}://${hostName}:${port}/api/`;
}

export function isCanceled(error: any) {
	return error && error.message === "canceled";
}
