import {
	ApplicationInsights,
	ICustomProperties,
} from "@microsoft/applicationinsights-web";

const instrumentationKey =
	process.env.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY || "";
const enabled = Boolean(instrumentationKey.length);

const appInsights = new ApplicationInsights({
	config: {
		instrumentationKey: instrumentationKey,
		enableAutoRouteTracking: true,
	},
});

export function init() {
	if (enabled) {
		appInsights.loadAppInsights();
		appInsights.trackPageView();
	}
}

export function trackEvent(name: string, customProperties: ICustomProperties) {
	if (enabled) {
		appInsights.trackEvent({ name }, customProperties);
	}
}
