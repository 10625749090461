import { WithClassName } from "../../theme/WithClassName";

export function Logo({
	className,
	variant,
}: WithClassName & { variant?: "orange" | "black" }) {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			version="1.1"
			id="Layer_1"
			x="0px"
			y="0px"
			viewBox="0 0 118 108"
			xmlSpace="preserve"
			fill={variant === "black" ? "black" : "#feab00"}
		>
			<g>
				<g>
					<path
						className="st0"
						d="M8.4,66.3c2.2,36.2,40.8,41.5,47.5,41.5c5.8,0,35.4,1.1,48.7-27.6s0.9-45.8,0.9-45.8    c-2.9,0.6,0.3,62.5-48.1,61.9c0,0-35.2,0-38.4-30.8c0,0-3.3-17.1,17.5-39.4c0,0,2.1-1.9-0.5-4.5s-1.7-6.2,1.6-7.7    c4-1.8,6.8,0.6,7.4,2.4c0.9,2.7-0.8,4.3-0.4,5.4c4,8.8-4.7,17.3-4.7,34.6c0,6.3,5.1,15.6,18.1,15.6C74.4,71.8,77,55,77.5,53    c1.3-5.6,6.2-44.5,6.2-44.5c-3-2.7-11.8-7.9-16.3-7.1c0,39.7,7.1,61.2-9.6,61.2c-19,0,2.6-39.8-12.3-49.2c0,0-2-1.2,2.5-1.1    c5.4,0.1,7.1,1.8,9.7,2c2.6,0.2,4.1-3.1,4.8-4.9s0.4-9,0.4-9s-2.9-0.7-6.3,2c-1.3,1-2.9,3.7-4.6,3.2c-1.6-0.5-1.3-2.3,0.2-4.4    s-1.8-1.2-3.4,0c-0.9,0.7-1.6,2-4.2,4.9c-1.6,1.8-2.8,0.1-2.5-1c0.2-0.8,1-2.9,1-2.9s-3.9-2.5-6.3,7.2c0,0-1,1.3-1.9-0.1    C34,8,33.3,5.2,33.3,5.2s-7.4,0.6-7.2,6.5c0.1,1.5,5.8,1.8,5.8,1.8s2.7,0.8,0.2,1.2s-6.7-0.1-9.1,4.6C22.1,21,29.8,20,29.8,20    s2.3,0.8-0.3,2c-1.4,1-11.5,0-15.1,2.8c-2.4,1.4-7.5,9.4-3.5,13c3.3,3,14.1-5.9,16.8-8.1c1-0.8,3.8-3,4.2-2.6    c0.5,0.5,0,1.6-2.5,3.6C27.1,32.5,7.1,46.2,8.4,66.3L8.4,66.3z"
					/>
				</g>
				<g>
					<path
						className="st0"
						d="M100.3,23.7c-2-4.7-8.4-10.3-10.4-10.3c0,0-4,32.5-6.6,42.7C80.7,66.3,76.2,78.5,58,78.5    c-16.7,0-23.4-8.9-24.7-18.5c-1.8-12.9,8.2-26.6,9.3-31.4c1.1-4.9-1.5-5.8-1.5-5.8c0,1.6-1.9,4.6-2.5,5.6    C29.2,43.7,25.4,48,25.4,60.6c0,17.5,14.6,29,33.2,29C81,89.6,88.3,71.1,89.8,68C96.8,54.2,102.3,28.4,100.3,23.7L100.3,23.7z"
					/>
				</g>
				<g>
					<path
						className="st0"
						d="M42.5,18.1c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5C41.3,15.6,42.5,16.7,42.5,18.1L42.5,18.1    z"
					/>
				</g>
			</g>
		</svg>
	);
}
