import { ReactNode } from "react";

export function FullScreenInfoPage({
	children,
	variant = "white",
}: {
	children: ReactNode;
	variant?: "white" | "orange";
}) {
	return (
		<section
			className={`hero is-fullheight is-${
				variant === "white" ? "white" : "warning"
			}`}
		>
			<div className="hero-body">
				<div className="container has-text-centered">{children}</div>
			</div>
		</section>
	);
}
