import { useCallback, useEffect, useState } from "react";
import { KanavaApiException } from "../client/error";
import { useAuth } from "../context/AuthProvider";
import { isExpiredSessionError } from "../util/session";
import { isCanceled } from "../client/ApiClient";

export function useApiError(options?: { ignoreStatusCodes: number[] }) {
	const [error, setError] = useState<KanavaApiException | undefined>();
	const [shouldThrow, setShouldThrow] = useState(false);
	const { markSessionExpired } = useAuth();

	useEffect(() => {
		if (isExpiredSessionError(error)) {
			markSessionExpired();
		} else if (
			error instanceof KanavaApiException &&
			!(
				options &&
				error.statusCode &&
				options.ignoreStatusCodes.includes(error.statusCode)
			)
		) {
			setShouldThrow(true);
		}

		if (shouldThrow) {
			setShouldThrow(false);
		}
	}, [options, error, markSessionExpired, shouldThrow]);

	if (shouldThrow) {
		throw error;
	}

	return {
		error,
		setError: useCallback(
			(error: KanavaApiException | undefined) => {
				if (!isCanceled(error)) {
					setError(error);
				}
			},
			[setError]
		),
	};
}
