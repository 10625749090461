import React, { Suspense, Dispatch, useState, ReactNode } from "react";
import { NetworkStatusProvider } from "./context/NetworkStatusProvider";
import { FullScreenLoader } from "./components/common/Loader";
import { LocalizationProvider } from "./context/LocalizationProvider";
import { BrowserRouter } from "react-router-dom";
import "./theme/theme.scss";

const LazyApp = React.lazy(
	() => import(/* webpackChunkName: "lazy-app" */ "./LazyApp")
);

let setErrorBoundaryKeyValue:
	| Dispatch<React.SetStateAction<number>>
	| undefined = undefined;

export function resetErrorBoundary() {
	if (setErrorBoundaryKeyValue) {
		setErrorBoundaryKeyValue((prevState) => prevState + 1);
	}
}

export default function App() {
	const [errorBoundaryKey, setErrorBoundaryKey] = useState(0);
	setErrorBoundaryKeyValue = setErrorBoundaryKey;

	return (
		<AppWrapper>
			<Suspense fallback={<FullScreenLoader />}>
				<LazyApp errorBoundaryKey={errorBoundaryKey} />
			</Suspense>
		</AppWrapper>
	);
}

export function AppWrapper({ children }: { children: ReactNode }) {
	const baseUrl =
		document.getElementsByTagName("base")[0]?.getAttribute("href") ||
		undefined;
	return (
		<BrowserRouter basename={baseUrl}>
			<LocalizationProvider>
				<NetworkStatusProvider>{children}</NetworkStatusProvider>
			</LocalizationProvider>
		</BrowserRouter>
	);
}
