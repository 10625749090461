export function persistToLocalStorage(key: string, data: any) {
	localStorage.setItem(key, JSON.stringify(data));
}

export function readFromLocalStorage(key: string) {
	const persistedString = localStorage.getItem(key);
	if (persistedString) {
		return JSON.parse(persistedString);
	} else {
		return null;
	}
}

export function removeFromLocalStorage(key: string) {
	localStorage.removeItem(key);
}

export function purgeLocalStorage() {
	localStorage.clear();
}
