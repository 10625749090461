import rg4js from "raygun4js";

const apiKey = process.env.REACT_APP_RAYGUN_API_KEY || "";
const enabled = Boolean(apiKey.length);

export function init() {
	if (!enabled) {
		return;
	}
	rg4js("apiKey", apiKey);
	rg4js("enableCrashReporting", true);
	rg4js("withTags", ["Kanava Web Client App"]);
}
