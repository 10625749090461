import { LoginCredentials } from "../views/Login/interfaces";
import React, {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { ApiClient, setToken } from "../client/ApiClient";
import { ApiRoutes, StaticRoutes } from "../routes";
import { User } from "../interfaces/api/User";
import { KanavaApiException } from "../client/error";
import { FullScreenLoader } from "../components/common/Loader";

export interface AuthContextType {
	user?: User;
	loading: boolean;
	error?: KanavaApiException;
	login: (data: LoginCredentials) => Promise<void>;
	logout: () => Promise<void>;
	markSessionExpired: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({
	children,
}: {
	children: ReactNode;
}): JSX.Element {
	const [user, setUser] = useState<User | undefined>();
	const [error, setError] = useState<KanavaApiException | undefined>();
	const [loading, setLoading] = useState<boolean>(false);
	const [initialLoading, setInitialLoading] = useState<boolean>(true);
	const navigate = useNavigate();

	const setUserState = useCallback(
		(user?: User) => {
			setUser(user);
			setToken(user?.token || null);
		},
		[setUser]
	);

	useEffect(() => {
		const { promise, abort } = ApiClient.get<User>(ApiRoutes.Userinfo);
		promise
			.then(setUserState)
			.catch(setError)
			.finally(() => setInitialLoading(false));
		return abort;
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const login = useCallback(
		(credentials: LoginCredentials) => {
			setLoading(true);
			ApiClient.post<any>(ApiRoutes.Login, credentials)
				.then(() => ApiClient.get<User>(ApiRoutes.Userinfo).promise)
				.then(setUserState)
				.catch(setError)
				.finally(() => setLoading(false));
		},
		[setLoading, setUserState, setError]
	);

	const logout = useCallback(
		() =>
			ApiClient.post<any>(ApiRoutes.Logout, null)
				.then(() => {
					setUserState(undefined);
					navigate(StaticRoutes.Login);
				})
				.catch(setError),
		[setUserState, navigate, setError]
	);

	const markSessionExpired = useCallback(
		() => setUserState(undefined),
		[setUserState]
	);

	const memoedValue = useMemo(
		() => ({
			user,
			loading,
			error,
			login,
			logout,
			markSessionExpired,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[user, loading, error]
	);

	return (
		<AuthContext.Provider value={memoedValue as AuthContextType}>
			{initialLoading ? <FullScreenLoader /> : children}
		</AuthContext.Provider>
	);
}

export function useAuth(): AuthContextType {
	return useContext(AuthContext);
}

export function useAuthLoggedIn(): Required<Omit<AuthContextType, "error">> &
	Pick<AuthContextType, "error"> {
	const { user, ...rest } = useAuth();
	if (!user) {
		throw Error("useAuth: Expected user to be logged in");
	}
	return { user, ...rest };
}
