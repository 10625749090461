import React, { useEffect, useState } from "react";
import { Logo } from "../svg/Logo";
import { FullScreenInfoPage } from "./FullScreenInfoPage";
import { FormattedMessage } from "react-intl";
import { Icon } from "@mdi/react";
import { mdiAirplane } from "@mdi/js";

const OFFLINE_INSTRUCTION_TIMEOUT_MS = 1000 * 5;

export function FullScreenLoader({
	hideOfflineInstructions,
}: {
	hideOfflineInstructions?: boolean;
}) {
	return (
		<FullScreenInfoPage>
			<Loader showOfflineInstructions={!hideOfflineInstructions} />
		</FullScreenInfoPage>
	);
}

export function PageLoader() {
	return (
		<section className="hero is-halfheight">
			<div className="hero-body">
				<div className="container has-text-centered">
					<Loader showOfflineInstructions />
				</div>
			</div>
		</section>
	);
}

export function ListLoader() {
	return <LoaderProgress />;
}

function LoaderProgress() {
	return <progress className="progress is-large is-black" max="100" />;
}

function Loader({
	showOfflineInstructions,
}: {
	showOfflineInstructions?: boolean;
}) {
	return (
		<>
			<div className="content">
				<Logo variant="black" className="icon is-large" />
			</div>
			<LoaderProgress />
			{showOfflineInstructions ? (
				<OfflineInstructionsWithTimeout />
			) : null}
		</>
	);
}

function OfflineInstructionsWithTimeout() {
	const showOfflineInstructions = useOfflineInstructionTimeout();
	return showOfflineInstructions ? (
		<div className="notification is-warning is-light has-text-black">
			<div>
				<FormattedMessage id="Loader_OfflineInstructions" />
			</div>
			<div className="icon">
				<Icon path={mdiAirplane} />
			</div>
		</div>
	) : null;
}

function useOfflineInstructionTimeout() {
	const [showInstructions, setShowInstructions] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(
			() => setShowInstructions(true),
			OFFLINE_INSTRUCTION_TIMEOUT_MS
		);
		return () => clearTimeout(timeout);
	}, []);

	return showInstructions;
}
