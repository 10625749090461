import {
	persistToLocalStorage,
	readFromLocalStorage,
} from "../util/localStorage";
import { differenceInDays } from "date-fns";

const localStorageKeyPrefix = "cached-response:";

function getCacheKey(url: string) {
	return `${localStorageKeyPrefix}${url}`;
}

export function writeToCache(url: string, response: any) {
	persistToLocalStorage(getCacheKey(url), timestampResponse(response));
}

export function readFromCache(url: string) {
	return parseTimestampedResponse(readFromLocalStorage(getCacheKey(url)));
}

function timestampResponse(response: any) {
	return {
		timestamp: Date.now(),
		response,
	};
}

function parseTimestampedResponse(timestampedResponse: any) {
	return timestampedResponse?.response;
}

export function removeCacheItemsOlderThanDays(days: number) {
	getAllCachedResponseKeys().forEach((cachedResponseKey) =>
		// Doing this asynchronously to prevent UI from potentially freezing if removing a lot of items
		setTimeout(
			() =>
				removeItemIfLacksTimeStampOrOlderThanDays(
					cachedResponseKey,
					days
				),
			0
		)
	);
}

function removeItemIfLacksTimeStampOrOlderThanDays(
	cachedResponseKey: string,
	days: number
) {
	const timestampedResponse = readFromLocalStorage(cachedResponseKey);
	if (!timestampedResponse?.timestamp) {
		localStorage.removeItem(cachedResponseKey);
	} else {
		const responseTime = new Date(timestampedResponse.timestamp);
		const now = new Date();
		if (days < differenceInDays(now, responseTime)) {
			localStorage.removeItem(cachedResponseKey);
		}
	}
}

function getAllCachedResponseKeys() {
	const cachedResponseKeys = [];
	for (let index = 0; index < localStorage.length; index++) {
		const itemKey = localStorage.key(index);
		if (itemKey && itemKey.includes(localStorageKeyPrefix)) {
			cachedResponseKeys.push(itemKey);
		}
	}
	return cachedResponseKeys;
}
