import { assertNever } from "./assert";

export enum Locale {
	fi = "fi",
	sv = "sv",
	en = "en",
}

export enum Language {
	Finnish = "Finnish",
	Swedish = "Swedish",
	English = "English",
}

export function localeToLanguage(locale: Locale): Language {
	switch (locale) {
		case Locale.fi:
			return Language.Finnish;
		case Locale.sv:
			return Language.Swedish;
		case Locale.en:
			return Language.English;
		default:
			assertNever(locale);
	}
}

export function languageToLocale(language: Language): Locale {
	switch (language) {
		case Language.Finnish:
			return Locale.fi;
		case Language.Swedish:
			return Locale.sv;
		case Language.English:
			return Locale.en;
		default:
			assertNever(language);
	}
}
