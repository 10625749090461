import { createRoot } from "react-dom/client";
import App from "./App";
import { init as initErrorLogger } from "./util/errorLogger";
import { init as initApplicationInsights } from "./util/applicationInsights";

initErrorLogger();
initApplicationInsights();

const root = createRoot(document.getElementById("root")!);
root.render(<App />);
