import { format } from "date-fns";

export function formatNullableDate(
	value?: string | Date | null,
	symbolToPrintIfEmpty?: string
) {
	if (!value) {
		return symbolToPrintIfEmpty ? symbolToPrintIfEmpty : "";
	}
	let date = typeof value === "string" ? new Date(value) : value;

	return format(date, "d.M.yyyy");
}

export function formatDate(date: Date) {
	return format(date, "d.M.yyyy");
}

export function formatDateTimeWithoutYear(date: Date) {
	return format(date, "d.M. H:mm");
}

export function formatDateRange(start: Date, end?: Date) {
	const formattedStart =
		!end || start.getFullYear() !== end.getFullYear()
			? formatDate(start)
			: start.getMonth() !== end.getMonth()
			? format(start, "d.M.")
			: format(start, "d.");

	return `${formattedStart}-${end ? `${formatDate(end)}` : ">"}`;
}
export function dateToDateString(date: Date) {
	return format(date, "yyyy-MM-dd");
}
