import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { ApiClient } from "../client/ApiClient";
import { IntlProvider } from "react-intl";
import {
	persistToLocalStorage,
	readFromLocalStorage,
} from "../util/localStorage";
import { DynamicApiRoutes } from "../routes";
import { Locale } from "../util/locale";
import { useApiError } from "../hooks/useApiError";
import { FullScreenLoader } from "../components/common/Loader";
export { Locale };

type Localizations = Record<string, string>;

interface LocaleState {
	locale: Locale;
	setLocale: (locale: Locale) => void;
}

let localStorageLocaleKey = "locale";

const LocaleContext = createContext<LocaleState>({
	locale: Locale.fi,
	setLocale: () => {},
});

export function LocalizationProvider({ children }: { children: ReactNode }) {
	const [locale, setLocale] = useState(
		readFromLocalStorage(localStorageLocaleKey) || Locale.fi
	);
	const { setError } = useApiError();
	const [messages, setMessages] = useState<Localizations | undefined>(
		undefined
	);
	useEffect(() => {
		const { promise, abort } = ApiClient.get<Localizations>(
			DynamicApiRoutes.Resources(locale)
		);
		promise.then(setMessages).catch(setError);
		persistToLocalStorage(localStorageLocaleKey, locale);
		return abort;
	}, [locale, setError]);

	const value = useMemo(() => ({ locale, setLocale }), [locale, setLocale]);

	return process.env.NODE_ENV === "test" || messages ? (
		<LocaleContext.Provider value={value}>
			<IntlProvider
				locale={locale}
				messages={messages}
				onError={(err) => {
					if (err.code === "MISSING_TRANSLATION") {
						if (process.env.NODE_ENV === "test") {
							return;
						}
						console.warn("Missing translation", err.message);
						return;
					}
					throw err;
				}}
			>
				{children}
			</IntlProvider>
		</LocaleContext.Provider>
	) : (
		<FullScreenLoader hideOfflineInstructions />
	);
}

export function useLocale() {
	return useContext(LocaleContext);
}
