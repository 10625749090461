import {
	createContext,
	Dispatch,
	ReactNode,
	useContext,
	useState,
} from "react";

export let setOnlineStatus: Dispatch<boolean> = () => ({});

const NetworkStatusContext = createContext<boolean>(false);

export function NetworkStatusProvider({ children }: { children: ReactNode }) {
	const [online, setOnline] = useState(false);
	setOnlineStatus = setOnline;
	return (
		<NetworkStatusContext.Provider value={online}>
			{children}
		</NetworkStatusContext.Provider>
	);
}

export function useNetworkStatus() {
	return useContext(NetworkStatusContext);
}
